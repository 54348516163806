import { defineStore } from 'pinia';
import { mapProperty } from '~/units/properties/store';
import { PropertiesService } from '~/units/properties/services';
import type {
  PropertiesFilter,
  PropertiesFilterKeys,
  PropertiesFilterRouteQueryKeys,
  PropertiesInitialConfig,
  PropertiesQuery,
  PropertyItem,
  PropertyItemComponent,
} from '~/units/properties/types';
import { PFilterInclusionBase, PFilterSearchType, PFilterSortOptions } from '~/units/properties/types';
import { FetchModes, RepoStates, AppSessionKeys } from '~/units/core/types';
import { useContactsStore } from '~/units/contacts/store';
import type { Component } from '~/types';
import { useDataFromState } from '~/composables';
import { useCoreStore } from '~/units/core/store';
import { ROUTES } from '~/constants/ui.constants';
import { RequestName, RequestProxy, useCaller } from '~/hydration/bridges';
import { useWebsiteStore } from '~/units/website/store';

export const usePropertiesStore = defineStore({
  id: 'properties',
  state: (): {
    propertiesGrid: {
      data: [];
      meta: {
        after_key: string | null;
        total: number;
      } | null;
      isInitialFetched: boolean;
      routeQueryFilters: PropertiesFilterRouteQueryKeys | NonNullable<unknown>;
      initialLoadingConfig: PropertiesInitialConfig;

      awaitingRouteQueryToPush: PropertiesFilterRouteQueryKeys | NonNullable<unknown>;
    };
    specialCriteria: any;
  } => {
    return {
      propertiesGrid: {
        data: [],
        meta: null,
        isInitialFetched: false,
        routeQueryFilters: {},
        initialLoadingConfig: {
          is_available: true,
        },

        awaitingRouteQueryToPush: {},
      },
      specialCriteria: {},
    };
  },
  actions: {
    setSpecialCriteria(key: string, data: any[]) {
      this.specialCriteria[key] = data;
    },

    generateRouteQuery(mode: FetchModes, query: PropertiesQuery, body: PropertiesFilter, order: PFilterSortOptions) {
      const newQuery: { [key: string]: string } = {};

      const inclusionFilterKeys = [
        'new_homes',
        'shared_ownership',
        'retirement_homes',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'furnishing',
      ];

      Object.keys(body).forEach((field: string) => {
        const key = field as PropertiesFilterKeys;
        const value = body[key];

        if (Array.isArray(value)) {
          if (value.length > 0) {
            newQuery[key] = JSON.stringify(value);
          }
        } else if (inclusionFilterKeys.includes(field)) {
          if (value && PFilterInclusionBase.INCLUDE.toString() !== value.toString()) {
            newQuery[key] = value.toString();
          }
        } else if (value) {
          newQuery[key] = value.toString();
        }
      });

      Object.keys(query).forEach((field: string) => {
        const key = field as PropertiesFilterRouteQueryKeys;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const value = query[key];

        newQuery[key] = value?.toString();
      });

      if (order) {
        newQuery.sort = order;
      }

      if (newQuery && Object.keys(newQuery).length) {
        return newQuery;
      }
    },

    async fetchSingleProperty(component: Component) {
      await callOnce(async () => {
        const nuxtApp = useNuxtApp();

        await nuxtApp.runWithContext(async () => {
          const route = useRoute();
          const slug = route.params.property_id as string;

          if (slug) {
            await fetchPropertyManager(component, slug);
          }
        });

        nuxtApp.runWithContext(() => {
          const route = useRoute();

          const { data } = useDataFromState(RepoStates.SINGLE_PROPERTY_REPO);

          if (data && data[0] && data[0].data) {
            const property = mapProperty({ info: data[0].data }, 0);

            if (route.params.property_slug === 'property') {
              navigateTo({
                path: property.detail_page_url,
                query: route.query,
              });
            }
            const store = useCoreStore();
            store.updateMetaTagsGroup({
              title: property.advert_heading,
              seo_meta_title: property.advert_heading,
              og_meta_title: property.advert_heading,
              seo_meta_description: property.summary || '',
              og_meta_description: property.summary || '',
              og_meta_image: property.image,
            });
          } else {
            navigateTo({
              path: '/not-found',
              query: {
                path: route.path,
              },
            });
          }
        });
      });
    },

    async fetch(
      mode: FetchModes,
      limit = 9,
      order: PFilterSortOptions,
      searchType: PFilterSearchType,
      query: PropertiesQuery,
      body: PropertiesFilter,
    ) {
      const isInitialRequest = mode === FetchModes.INIT;
      const isInitialRequestSentBefore = !!this.propertiesGrid.isInitialFetched;

      if ((isInitialRequest && !isInitialRequestSentBefore) || !isInitialRequest) {
        const propertiesService = new PropertiesService();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error

        const requestQuery: PropertiesQuery & {
          search_type: PFilterSearchType;
          is_website: boolean;
          is_available: boolean;
          __limit: number;
          __order_by: PFilterSortOptions;
          after_key?: string;
        } = {
          search_type: searchType,
          is_website: true,
          __limit: limit,
          __order_by: order,
          ...query,
          ...this.specialCriteria,
        };
        if (mode === FetchModes.LOAD_MORE && this.propertiesGrid?.meta?.after_key) {
          requestQuery.after_key = `[${this.propertiesGrid.meta?.after_key}]`;
        }

        const highlightedCriteria = (() => {
          const contactsStore = useContactsStore();
          const sessionData = contactsStore.sessionData;

          if (
            sessionData &&
            sessionData[AppSessionKeys.HIGHLIGHTED_PROPERTIES_CRITERIA] &&
            sessionData[AppSessionKeys.HIGHLIGHTED_PROPERTIES_CRITERIA].criteria
          ) {
            return sessionData[AppSessionKeys.HIGHLIGHTED_PROPERTIES_CRITERIA].criteria;
          }
          return {};
        })();

        const { data, meta } = await propertiesService.fetchProperties(
          {
            ...requestQuery,
            ...highlightedCriteria,
          },
          body,
        );

        if (mode === FetchModes.INIT || mode === FetchModes.LOAD_BY_DATA) {
          this.propertiesGrid.routeQueryFilters = {
            ...body,
            ...query,
            sort: order,
          };
        }

        if (mode === FetchModes.LOAD_MORE) {
          this.propertiesGrid.data = this.propertiesGrid.data ? [...this.propertiesGrid.data, ...data] : data;
        } else {
          this.propertiesGrid.data = data;
        }
        this.propertiesGrid.meta = meta;
        this.propertiesGrid.isInitialFetched = true;

        if ([FetchModes.SEARCH, FetchModes.SORT].includes(mode)) {
          const newRouteQuery = this.generateRouteQuery(mode, query, body, order);
          this.handleAwaitingRouteQuery(newRouteQuery);
        } else if (FetchModes.LOAD_BY_DATA === mode) {
          const newRouteQuery = this.generateRouteQuery(mode, query, body, order);

          this.handleAwaitingRouteQuery(newRouteQuery);
        }
      }
    },

    setInitialLoadConfig(config: PropertiesInitialConfig) {
      this.propertiesGrid.initialLoadingConfig = config;
    },

    async handleAwaitingRouteQuery(query: any) {
      if (Object.keys(query).length) {
        if (process.client) {
          const router = useRouter();

          await router.replace({
            query,
          });
          this.propertiesGrid.awaitingRouteQueryToPush = {};
        } else {
          this.propertiesGrid.awaitingRouteQueryToPush = query;
        }
      }
    },

    prepareDefaultSort(component: Component) {
      const value = getVariable(component, 'default_sort-text') as PFilterSortOptions;
      if (value && PFilterSortOptions[value]) {
        return PFilterSortOptions[value];
      }
      return PFilterSortOptions.SUGGESTED;
    },

    async goBackToPropertyList() {
      navigateTo(
        {
          path: ROUTES.PROPERTIES,
        },
        {
          external: true,
        },
      );
    },

    async addPotentialViewing(propertyProfileId: number) {
      try {
        const contactsStore = useContactsStore();
        const websitesStore = useWebsiteStore();

        const contactInfo = contactsStore.contactInfo;

        if (contactInfo) {
          const body = {
            contact_id: contactInfo?.contact_id,
            contact_applicant_id: contactInfo.applicant_information?.latest_contact_applicant_id,
            property_profile_id: propertyProfileId,
            company_id: websitesStore.company?.ls_company_id,
          };

          await useCaller(
            RequestProxy.LS_AXON,
            RequestName.ADD_POTENTIAL_VIEWING,
            'presentation/potential_viewings',
            'POST',
            {},
            body,
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    propertiesGridList: (state): PropertyItemComponent[] => {
      if (state.propertiesGrid.data) {
        return state.propertiesGrid.data
          .filter((item: PropertyItem) => !item.is_featured)
          .map((item, index) => mapProperty(item, index));
      }
      return [];
    },

    highlightedPropertiesGridList: (state): PropertyItemComponent[] => {
      if (state.propertiesGrid.data) {
        return state.propertiesGrid.data
          .filter((item: PropertyItem) => item.is_featured)
          .map((item: PropertyItem, index) => mapProperty(item, index));
      }
      return [];
    },

    propertiesGridCount: (state): number => {
      return state.propertiesGrid.meta?.total || 0;
    },
    isPropertiesGridLoadMoreActive: (state): boolean => {
      const store = usePropertiesStore();

      if (store.highlightedPropertiesGridList.length) {
        return store.propertiesGridCount > store.propertiesGridList.length + store.highlightedPropertiesGridList.length;
      }
      return store.propertiesGridCount > store.propertiesGridList.length;
    },

    propertiesGridInitialRouteQuery: (state) => {
      return state.propertiesGrid.routeQueryFilters;
    },

    propertiesGridInitialLoadConfig: (state) => {
      return state.propertiesGrid.initialLoadingConfig;
    },

    propertiesGridAwaitingRouteQuery(state) {
      return state.propertiesGrid.awaitingRouteQueryToPush;
    },
  },
});
